import { getRoutePathByItem } from "@constants";

// don't change THUMBNAILS name/type, it's used by `pages-loader` webpack loader
// see scripts/webpack/loaders/pages-loader.js
// prototype: device/name => thumbnail
const Campaign_THUMBNAILS = {
  "desktop/carousel-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDAREAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABAP/xAAdEAACAgIDAQAAAAAAAAAAAAABAgQRAAMhIjEy/8QAFQEBAQAAAAAAAAAAAAAAAAAAAQL/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEUH/2gAMAwEAAhEDEQA/ADSTrMfoALqzzk6U4aq0tArge/N35lA1tiKxAUtR5JOVA//Z",
  "desktop/carousel-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDAREAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAwX/xAAgEAABAwQCAwAAAAAAAAAAAAABAhESAAMEMRMUIVHh/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAwDAQACEQMRAD8ADH64ybcCJvoA1IKNyPEqTkNpqAkFQHlIL+/lWD//2Q==",
  "desktop/carousel-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAHABQDAREAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAABAX/xAAeEAACAQQDAQAAAAAAAAAAAAABAhEAAxIhE0NRYf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAMAwEAAhEDEQA/AHFTszqKKjqHe9jnJnyKkKRw3l65+hqRI//Z",
  "desktop/bottom-1": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAMF/8QAIRAAAgICAQQDAAAAAAAAAAAAAQIDEQASIQQFIjFBcZH/xAAWAQEBAQAAAAAAAAAAAAAAAAABAAL/xAAXEQEBAQEAAAAAAAAAAAAAAAAAAREx/9oADAMBAAIRAxEAPwB86QTKWIG1e65w2VCSTL0qhFNq2Z7ws+SbzNAfmWA2MSgDbY/RzUmJVQwoPGbr5rLCnJ21pnMgKrtyReGUP//Z",
  "desktop/bottom-2": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAwEEBf/EACEQAAIBAgYDAAAAAAAAAAAAAAECAAMRBBITITFBIjSB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgEA/8QAGREBAQEBAQEAAAAAAAAAAAAAAQARIUFR/9oADAMBAAIRAxEAPwDOenZFZXuTzCKvZoBRqMORtEci9lSq6r4MQItjlaYpQ9hFzdEbkiHfk8PYTitQEBKYHVwZsosOQDhj8tLkdv/Z",
  "desktop/bottom-3": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwQB/8QAIRAAAgECBgMAAAAAAAAAAAAAAQIDABEEEhMhMVEyQZH/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgD/xAAXEQEBAQEAAAAAAAAAAAAAAAAAEQEh/9oADAMBAAIRAxEAPwCfEIQ4IBse+qnWPHErxeQzD1TAKaLTlZWYVO8JJNSQAKq7CxJFKk7y5bC+x5piWyY2RnvZflaGv//Z",
  "desktop/bottom-4": "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAlgCWAAD/2wBDACgcHiMeGSgjISMtKygwPGRBPDc3PHtYXUlkkYCZlo+AjIqgtObDoKrarYqMyP/L2u71////m8H////6/+b9//j/2wBDASstLTw1PHZBQXb4pYyl+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj4+Pj/wAARCAAQABQDAREAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwEA/8QAJBAAAQMCBAcAAAAAAAAAAAAAAQACAwQRFCEyUSIxQkORkqH/xAAXAQEBAQEAAAAAAAAAAAAAAAABAAID/8QAGBEBAQEBAQAAAAAAAAAAAAAAACEBEVH/2gAMAwEAAhEDEQA/AFNZIe2EwVDVvbqj+piqCtv0D2R3PVRvhZOBxWtvmuea22FFtY5bJ6CCEgZOb4WYX//Z",
  "mobile/carousel-1": false,
  "mobile/carousel-2": false,
  "mobile/carousel-3": true,
  "mobile/bottom-1": true,
  "mobile/bottom-2": true,
  "mobile/bottom-3": true
};

const getThumbnailByKey = imgPath => {
  const key = Object.keys(Campaign_THUMBNAILS).find(
    k => imgPath.indexOf(k) !== -1
  );

  return Campaign_THUMBNAILS[key] || false;
};

export default ({ imgParams, pathfinder, i18n }) => {
  const _i18n = i18n.pages.Campaign;

  const extractImage = src => {
    const re = new RegExp(`^v(\\d+)\\/${imgParams.cloudinary.path}\\/(.+)$`);

    const m = re.exec(src) || {};

    return { src: m[2], version: m[1] };
  };

  const buildItem = (item, props = {}, loading = null) => ({
    img: {
      ...extractImage(item.IMG || ""),
      thumbnail: getThumbnailByKey(item.IMG),
      fluid: true,
      width: "100%",
      height: "100%",
      title: item.TITLE,
      importance: "high",
      loading,
      cloudinary: imgParams.cloudinary,
      sizes: props.sizes,
      aspect: props.aspect || 1,
      video: Boolean(item.VIDEO)
    },
    href: getRoutePathByItem(pathfinder, item)
  });

  const filterItem = (item, defaultValue = true) =>
    "undefined" === typeof item.ENABLED ? defaultValue : Boolean(item.ENABLED);

  const buildButton = button => {
    const href = getRoutePathByItem(pathfinder, button);

    if (!button.TITLE || !button.ENABLED || !href) {
      return false;
    }

    return {
      button: {
        block: true,
        variant: button.VARIANT,
        className: "px-1",
        onClick: e => {},
        href: getRoutePathByItem(pathfinder, button)
      },
      title: button.TITLE,
      icon: {
        transform: "right-2",
        icon: "angle-right"
      }
    };
  };

  const sizes = {
    carousel: {
      mobile: {
        sizes: {
          mobilePortrait: 376,
          mobileLandscape: 830,
          tabletPortrait: 754,
          tabletLandscape: 1010,
          desktop: 1207
        },
        aspect: 0.9149
      },
      desktop: {
        sizes: {
          mobilePortrait: 376,
          mobileLandscape: 830,
          tabletPortrait: 754,
          tabletLandscape: 1010,
          desktop: 1424
        },
        aspect: 0.3581
      }
    },
    bottomImg: {
      mobile: {
        sizes: {
          mobilePortrait: 390,
          mobileLandscape: 473,
          tabletPortrait: 213,
          tabletLandscape: 293,
          desktop: 298
        },
        aspect: 0.959
      },
      desktop: {
        sizes: {
          mobilePortrait: 390,
          mobileLandscape: 473,
          tabletPortrait: 213,
          tabletLandscape: 293,
          desktop: 350
        },
        aspect: 0.7982
      }
    }
  };

  const header =
    _i18n.header.TITLE || _i18n.header.TEXT
      ? { title: _i18n.header.TITLE, text: _i18n.header.TEXT }
      : null;

  const images = ["carousel", "bottomImg"].reduce((carry, key) => {
    return Object.assign(carry, {
      [key]: ["mobile", "desktop"].reduce((carry, device) => {
        return Object.assign(carry, {
          [device]: _i18n[key][device]
            .filter(item => filterItem(item))
            .map((item, index) =>
              buildItem(
                item,
                sizes[key][device],
                key === "bottomImg" || !index ? "eager" : null
              )
            )
        });
      }, {})
    });
  }, {});

  const buttons = _i18n.buttons.map(buildButton).filter(Boolean);

  return {
    ...images,
    header,
    buttons
  };
};
